import { IdAndName } from '.'
import { EmployeeOptionInterface, IdStatuses } from './employees'

export const PAYROLL_ISSUES_LEVEL_NONE = 0
export const PAYROLL_ISSUES_LEVEL_WARNING = 5
export const PAYROLL_ISSUES_LEVEL_CRITICAL = 10

export type PayrollIssuesLevelId =
  | typeof PAYROLL_ISSUES_LEVEL_NONE
  | typeof PAYROLL_ISSUES_LEVEL_WARNING
  | typeof PAYROLL_ISSUES_LEVEL_CRITICAL
export type PayrollStatusId = 'processed' | 'approved' | 'with_issues'

export interface PayGroupListItemInterface {
  id: number
  name: string
  payroll_provider: IdAndName
  current_pay_cycle: {
    id: number
    name: string
    start_date: string
    end_date: string
  }
  total_changes: number
  total_issues: number
  issues_level: IdAndName<PayrollIssuesLevelId>
  status: IdAndName<PayrollStatusId>
}

export interface PayCycleReportTableItemInterface {
  id: number
  employee: EmployeeOptionInterface
  is_hire: boolean
  is_termination: boolean
  issues_level: IdAndName<PayrollIssuesLevelId>
  salary_amount: number
  salary_currency: string
  total_changes: number
  total_issues: number
}

export interface PayCycleReportStatsInterface {
  total_approved: number
  total_changes: number
  total_criticals: number
  total_issues: number
  total_reports: number
  total_warnings: number
}

type PayCycleTrigger =
  | 'before_pay_date'
  | 'before_pay_cycle_ends'
  | 'after_pay_cycle_starts'

export type PayGroupEligibilityType = 'dynamic_group' | 'employee_filters'

export interface PayGroupInterface {
  id: number
  name: string
  country: IdAndName & { country_code: string }
  cut_off_date_trigger: IdAndName<PayCycleTrigger>
  cut_off_date_trigger_number_of_days: string
  pay_date_day: IdAndName
  pay_date_schedule: IdAndName<'current_month' | 'next_month'>
  pay_date_lands_on_weekend: IdAndName<
    'pay_on_the_previous_business_day' | 'pay_on_the_next_business_day'
  >
  pay_frequency: IdAndName<'monthly' | 'bi_weekly' | 'bi_monthly' | 'weekly'>
  pay_period_start_day: IdAndName
  payroll_provider: string
  report_submission_date_trigger: IdAndName<PayCycleTrigger>
  report_submission_date_days_after_cycle_ends: number
  eligibility_group: IdAndName<string> | null
  eligibility_group_type: PayGroupEligibilityType
}

export interface PayGroupEligibilityInterface {
  id: number
  name: string
  owner: EmployeeOptionInterface
  visibility: IdAndName
  table_filters: object
}

export type PayrunPreviewPayloadInterface = Pick<
  PayGroupInterface,
  | 'pay_date_day'
  | 'pay_frequency'
  | 'pay_date_schedule'
  | 'pay_period_start_day'
  | 'cut_off_date_trigger'
  | 'cut_off_date_trigger_number_of_days'
  | 'pay_date_lands_on_weekend'
  | 'report_submission_date_trigger'
  | 'report_submission_date_days_after_cycle_ends'
>

export type PayrunMonthSchedule = {
  start_date: string
  end_date: string
  cut_off_date: string
  submission_date: string
  pay_authorities_date: string | null
  pay_date: string
}

export type PayCycleStatus = 'open' | 'closed' | 'scheduled' | 'pending_closure'

export interface PayCycleInterface {
  id: number
  pay_group: PayGroupInterface
  start_date: string
  end_date: string
  status: IdAndName<PayCycleStatus>
}

export type PayrollEvent = 'termination' | 'hire' | 'data_change'
export type PayrollTimelineDomainCategory =
  | 'work_details'
  | 'contract'
  | 'personal_details'
  | 'time_off'
export type PayrollTimelineDomainName =
  | 'name'
  | 'status'
  | 'end_of_probation'
  | 'employee_type'
  | 'contract'
  | 'contract_type'
  | 'contract_status'
  | 'contract_term'
  | 'start_date'
  | 'end_date'
  | 'entity'
  | 'location'
  | 'salary'
  | 'sign_on_bonus'
  | 'position'
  | 'specialisation'
  | 'seniority'
  | 'job_title'
  | 'weekly_working_hours'
  | 'full_time_equivalent'
  | 'notice_period_during_probation'
  | 'notice_period_after_probation'
  | 'inactivity'
  | 'approval_status'
  | 'personal_email'
  | 'legal_sex'
  | 'marital_status'
  | 'birth_date'
  | 'phone_number'
  | 'nationality'
  | 'nationalities'
  | 'languages'
  | 'address'
  | 'emergency_contact'
  | 'time_off_request'
type PayrollTimelineEventType = 'modify'

export interface PayrollTimelineChangeInterface {
  id: number
  employee: EmployeeOptionInterface
  domain_category: PayrollTimelineDomainCategory
  domain_name: PayrollTimelineDomainName
  event_type: PayrollTimelineEventType
  from_value: PayrollTimelineChange | null
  to_value: PayrollTimelineChange
  issues_level: IdAndName<PayrollIssuesLevelId>
  issues: object
  effective_date_time: string
  processing_status: IdAndName<'success' | 'failed' | 'not_processed' | 'pending'>
}

export interface PayrollTimelineEventInterface {
  payroll_event: PayrollEvent
  effective_date_time: string
  content: PayrollTimelineChangeInterface
}

export type PayrollTimelineChange =
  | PayrollTimelineChangeName
  | PayrollTimelineChangeStatus
  | PayrollTimelineChangeEmployeeType
  | PayrollTimelineChangeContractFull
  | PayrollTimelineChangeContractType
  | PayrollTimelineChangeContractStatus
  | PayrollTimelineChangeContractTerm
  | PayrollTimelineChangeStartDate
  | PayrollTimelineChangeEndDate
  | PayrollTimelineChangeEntity
  | PayrollTimelineChangeLocation
  | PayrollTimelineChangeSalary
  | PayrollTimelineChangeSignOnBonus
  | PayrollTimelineChangePosition
  | PayrollTimelineChangeSpecialisation
  | PayrollTimelineChangeSeniority
  | PayrollTimelineChangeJobTitle
  | PayrollTimelineChangeWeeklyWorkingHours
  | PayrollTimelineChangeNoticePeriodDuringProbation
  | PayrollTimelineChangeNoticePeriodAfterProbation
  | PayrollTimelineChangeInactivity
  | PayrollTimelineChangeApprovalStatus
  | PayrollTimelineChangePersonalEmail
  | PayrollTimelineChangeLegalSex
  | PayrollTimelineChangeMaritalStatus
  | PayrollTimelineChangeBirthDate
  | PayrollTimelineChangePhoneNumber
  | PayrollTimelineChangeNationality
  | PayrollTimelineChangeNationalities
  | PayrollTimelineChangeLanguages
  | PayrollTimelineChangeAddress
  | PayrollTimelineChangeEmergencyContact
  | PayrollTimelineChangeTimeOffRequest

export type PayrollTimelineChangeName = {
  first_name: string
  middle_name: string
  last_name: string
  full_name: string
  name: string
}

export type PayrollTimelineChangeStatus = {
  status: IdAndName<IdStatuses>
}

export type PayrollTimelineChangeEmployeeType = {
  employee_type: string
}

export type PayrollTimelineChangeContractType = {
  contract_type: string
}

export type PayrollTimelineChangeContractStatus = {
  contract_status: string
}

export type PayrollTimelineChangeContractTerm = {
  contract_term: string
}

export type PayrollTimelineChangeContractFull = {
  approval_status: string
  company_entity: string
  contract_status: string
  contract_term: string
  contract_type: string
  end_date: string
  full_time_equivalent: number
  inactivity_end_date: string
  inactivity_reason: string
  inactivity_start_date: string
  location: string
  notice_period_after_probation: number
  notice_period_after_probation_unit: string
  notice_period_during_probation: number
  notice_period_during_probation_unit: string
  position: string
  salary_amount: string
  salary_currency: string
  salary_payment_frequency: string
  salary_time_unit: string
  seniority: string
  sign_on_bonus_amount: string
  sign_on_bonus_currency: string
  sign_on_bonus_type: string
  specialisation: string
  specialisation_seniority_sublevel: string
  start_date: string
  title: string
  weekly_working_hours: number
}

export type PayrollTimelineChangeStartDate = {
  start_date: string
}

export type PayrollTimelineChangeEndDate = {
  end_date: string
}

export type PayrollTimelineChangeEntity = {
  entity: string
}

export type PayrollTimelineChangeLocation = {
  location: string
}

export type PayrollTimelineChangeSalary = {
  salary_currency: string
  salary_amount: string
  salary_time_unit: string
  salary_payment_frequency: string
}

export type PayrollTimelineChangeSignOnBonus = {
  sign_on_bonus_type: string
  sign_on_bonus_amount: string
  sign_on_bonus_currency: string
}

export type PayrollTimelineChangePosition = {
  position: string
}

export type PayrollTimelineChangeSpecialisation = {
  specialisation: string
}

export type PayrollTimelineChangeSeniority = {
  seniority: string
  specialisation_seniority_sublevel: string
}

export type PayrollTimelineChangeJobTitle = {
  title: string
}

export type PayrollTimelineChangeWeeklyWorkingHours = {
  weekly_working_hours: string
}

export type PayrollTimelineChangeNoticePeriodDuringProbation = {
  notice_period_during_probation: string
  notice_period_during_probation_unit: string
}

export type PayrollTimelineChangeNoticePeriodAfterProbation = {
  notice_period_after_probation: string
  notice_period_after_probation_unit: string
}

export type PayrollTimelineChangeInactivity = {
  inactivity_reason: string
  inactivity_start_date: string
  inactivity_end_date: string
}

export type PayrollTimelineChangeApprovalStatus = {
  approval_status: string
}

export type PayrollTimelineChangePersonalEmail = {
  personal_email: string
}

export type PayrollTimelineChangeLegalSex = {
  legal_sex: string
}

export type PayrollTimelineChangeMaritalStatus = {
  marital_status: string
}

export type PayrollTimelineChangeBirthDate = {
  birth_date: string
}

export type PayrollTimelineChangePhoneNumber = {
  phone_number: string
  phone_country_code: string
  phone_number_short: string
}

export type PayrollTimelineChangeNationality = {
  nationality: string
}

export type PayrollTimelineChangeNationalities = {
  nationalities: string
}

export type PayrollTimelineChangeLanguages = {
  languages: string
}

export type PayrollTimelineChangeAddress = {
  country: string
  region: string
  county: string
  city: string
  post_code: string
  address_line_1: string
  address_line_2: string
  address_line_3: string
}

export type PayrollTimelineChangeEmergencyContact = {
  emergency_contact_full_name: string
  emergency_contact_email: string
  emergency_contact_mobile_phone: string
  emergency_contact_phone_country_code: string
  emergency_contact_phone_number: string
  emergency_contact_relationship: string
}

export type PayrollTimelineChangeTimeOffRequest = {
  balance: string
  from_date_time: string
  to_date_time: string
  start_date_time: string
  end_date_time: string
  from_time_period: string
  to_time_period: string
  status: string
  approval_status: string
  unit: string
  duration: string
  total_duration: string
}
