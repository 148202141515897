import React from 'react'
import { useSelector } from 'react-redux'
import { Box, MoreBar, StatusWidget } from '@revolut/ui-kit'

import { payGroupTableRequests } from '@src/api/payrollV2'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@src/components/Stat/Stat'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import {
  payGroupChangesNumColumn,
  payGroupHeadcountColumn,
  payGroupNameColumn,
  payGroupPayrollProviderColumn,
  payGroupPayRunColumn,
  payGroupStatusColumn,
} from '@src/constants/columns/payGroupV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PayGroupListItemInterface } from '@src/interfaces/payrollV2'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'

const Row: RowInterface<PayGroupListItemInterface> = {
  linkToForm: data => {
    const cycleId = data.current_pay_cycle?.id

    if (cycleId) {
      navigateTo(
        pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.OVERVIEW, {
          id: cycleId,
        }),
      )
    }
  },
  cells: [
    {
      ...payGroupNameColumn,
      width: 250,
    },
    {
      ...payGroupHeadcountColumn,
      width: 80,
    },
    {
      ...payGroupPayRunColumn,
      width: 80,
    },
    {
      ...payGroupPayrollProviderColumn,
      width: 120,
    },
    {
      ...payGroupChangesNumColumn,
      width: 100,
    },
    {
      ...payGroupStatusColumn,
      width: 100,
    },
  ],
}

export const PayGroupTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAddPaygroup = permissions.includes(PermissionTypes.CanAddPaygroup)

  const table = useTable(payGroupTableRequests)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Total" val={table.loading ? undefined : table.count} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAddPaygroup && (
            <MoreBar.Action
              variant="accent"
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT)}
            >
              Add new
            </MoreBar.Action>
          )}
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.PayGroups}
          dataType="paygroup"
          row={Row}
          useWindowScroll
          emptyState={
            <Box py="s-32">
              <StatusWidget>
                <StatusWidget.Image
                  image={{
                    default: 'https://assets.revolut.com/assets/3d-images-v2/3D028.png',
                    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@2x.png',
                    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D028@3x.png',
                  }}
                />
                <StatusWidget.Title>Nothing found</StatusWidget.Title>
                <StatusWidget.Description>
                  No existing paygroups yet
                </StatusWidget.Description>
              </StatusWidget>
            </Box>
          }
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
