import React, { useState } from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import { Button, Group, StatusPopup, useStatusPopup, VStack } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { payGroupRequests } from '@src/api/payrollV2'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageActions } from '@src/components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PayGroupInterface } from '@src/interfaces/payrollV2'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'

import { EligibilityConfig } from './EligibilityConfig'
import { PayrunScheduleWidget } from './PayrunScheduleWidget'
import { formatValuesToSubmit } from './helpers'

export const PayGroupEditConfirm = connect(() => {
  const params = useParams()
  const { values } = useLapeContext<PayGroupInterface>()

  const [customFilters, setCustomFilters] = useState<TableFilter>({})

  const statusPopup = useStatusPopup()

  return (
    <>
      <VStack space="s-16" pb="s-72">
        <FormPreview title="Summary" data={values}>
          <Group>
            <FormPreview.Item title="Paygroup name" field="name" />
            <FormPreview.Item title="Payroll provider" field="payroll_provider.name" />
            <FormPreview.Item title="Pay frequency" field="pay_frequency.name" />
          </Group>
        </FormPreview>
        <PayrunScheduleWidget />
        <EligibilityConfig
          readonly
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
        />
      </VStack>
      <PageActions>
        <NewSaveButtonWithPopup<PayGroupInterface>
          onAfterSubmit={res => {
            navigateTo(
              pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
                id: String(res.id),
              }),
            )
          }}
          onClick={async () => {
            if (values.id) {
              return payGroupRequests.update(formatValuesToSubmit(values), {
                id: String(values.id),
              })
            }
            let res
            try {
              res = await payGroupRequests.submit(formatValuesToSubmit(values), {})
            } catch (err) {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Something went wrong</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(err)}
                  </StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button
                      elevated
                      use={InternalLink}
                      to={pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT, params)}
                      onClick={() => statusPopup.hide()}
                    >
                      Edit pay group details
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
              throw err
            }
            return res?.data
          }}
        >
          Confirm
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
})
