import React, { useEffect, useState } from 'react'
import {
  Box,
  Cell,
  Flex,
  Group,
  HStack,
  Spinner,
  StatusPopup,
  Tag,
  Text,
  TextButton,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'

import { dynamicGroups } from '@src/api/dynamicGroups'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { AudienceTable, TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import { PayGroupEligibilityType, PayGroupInterface } from '@src/interfaces/payrollV2'
import { LapeRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'

type Props = {
  customFilters: TableFilter
  setCustomFilters: (newFilters: TableFilter) => void
  readonly?: boolean
}
export const EligibilityConfig = ({
  customFilters,
  setCustomFilters,
  readonly,
}: Props) => {
  const { values } = useLapeContext<PayGroupInterface>()

  const [isLoadingCustomFilters, setIsLoadingCustomFilters] = useState(false)

  useEffect(() => {
    if (values.eligibility_group_type == null) {
      values.eligibility_group_type = 'dynamic_group'
    }
  }, [])

  const loadCustomFilters = async () => {
    if (
      !values.eligibility_group?.id ||
      values.eligibility_group_type === 'dynamic_group'
    ) {
      return undefined
    }
    try {
      const dynamicGroup = await dynamicGroups.get({ id: values.eligibility_group.id })
      return dynamicGroup.data.table_filters
    } catch (err) {
      if (err.response?.status === 404) {
        console.warn(
          'Could not find eligibility custom filters data for an existing group',
        )
      } else {
        throw err
      }
    }
    return undefined
  }
  const statusPopup = useStatusPopup()

  useEffect(() => {
    if (
      values.eligibility_group_type === 'employee_filters' &&
      values.eligibility_group?.id
    ) {
      setIsLoadingCustomFilters(true)
      loadCustomFilters()
        .then(filters => {
          if (filters) {
            setCustomFilters(filters)
          }
        })
        .catch(() => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>
                Couldn't load custom filters for this eligibility group
              </StatusPopup.Title>
              <StatusPopup.Description>
                Please refresh the page or try again later
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => setIsLoadingCustomFilters(false))
    }
  }, [values.id, values.eligibility_group_type, values.eligibility_group?.id])

  return (
    <>
      <Box>
        {!readonly && <SectionTitle title="Who should we assign this paygroup to?" />}
        <LapeRadioSwitch<PayGroupEligibilityType>
          disabled={readonly}
          name="eligibility_group_type"
          variant="horizontal"
          options={[
            {
              id: 'eligibility_group',
              label: (
                <HStack space="s-8" align="center">
                  <Text>Groups</Text>
                  <Tag variant="faded">Recommended</Tag>
                </HStack>
              ),
              description: (
                <>
                  Assign employees meeting the eligibility criteria automatically to this
                  pay group{' '}
                  <TextButton to={ROUTES.APPS.GROUPS} use={InternalLink}>
                    here
                  </TextButton>
                  .
                </>
              ),
              value: 'dynamic_group',
            },
            {
              id: 'employee_filters',
              label: 'Custom filters',
              description: 'Assign eligibility for this pay group manually per employee.',
              value: 'employee_filters',
            },
          ]}
        />
        <Group mt="s-16">
          {values.eligibility_group_type === 'dynamic_group' && (
            <>
              <LapeRadioSelectInput
                name="eligibility_group"
                selector={selectorKeys.dynamic_groups}
                label="Eligibility group"
              />
            </>
          )}
          {values.eligibility_group_type === 'employee_filters' && (
            <Box p="s-16">
              {isLoadingCustomFilters ? (
                <Cell>
                  <Flex width="100%" justifyContent="center">
                    <Spinner color={Token.color.blue} />
                  </Flex>
                </Cell>
              ) : (
                <AudienceTable
                  isSimplifiedView
                  filter={customFilters}
                  withSidebarFilter={!readonly}
                  onFilterChange={newFilters => {
                    if (readonly) {
                      return
                    }
                    setCustomFilters(newFilters)
                  }}
                />
              )}
            </Box>
          )}
        </Group>
      </Box>
    </>
  )
}
