import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import {
  Box,
  Cell,
  Details,
  ExpandableCell,
  Flex,
  HStack,
  Icon,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PAYROLL_ISSUES_LEVEL_WARNING,
  PayrollTimelineChangeInterface,
} from '@src/interfaces/payrollV2'
import { ParsedDomainFieldChanges } from '..'
import { ValuesDiff } from '../ValuesDiff'

const getChangeHeaderInfo = (change: PayrollTimelineChangeInterface) => {
  const statusId = change.processing_status?.id
  const statusColor = (() => {
    switch (statusId) {
      case 'success':
        return Token.color.success
      case 'not_processed':
      case 'pending':
        return Token.color.warning
      case 'failed':
        return Token.color.danger
      default:
        return undefined
    }
  })()

  const tooltipBody = (
    <Box minWidth={240} p="s-8">
      <VStack>
        {change.processing_status && (
          <Box>
            <Text color={Token.color.background}>Deel integration: </Text>
            <Text color={statusColor || Token.color.background}>
              {change.processing_status.id === 'not_processed'
                ? 'Pending'
                : change.processing_status.name || 'unknown'}
            </Text>
          </Box>
        )}
        <Box>
          <Text color={Token.color.background}>Change done on: </Text>
          <Text whiteSpace="pre" color={Token.color.background}>
            {formatWithoutTimezone(change.effective_date_time, true)}
          </Text>
        </Box>
      </VStack>
    </Box>
  )

  return {
    domainName: formatSnakeCase(change.domain_name),
    infoTooltip: (
      <Tooltip
        placement="top"
        body={tooltipBody}
        backgroundColor={Token.color.foreground}
      >
        <Icon
          name="InfoOutline"
          color={statusColor || Token.color.greyTone50}
          size={14}
          mr="s-4"
        />
      </Tooltip>
    ),
  }
}

type SingleChangeCellProps = {
  change: PayrollTimelineChangeInterface
  label?: string
  children: React.ReactNode
}
export const SingleChangeCellWrapper = ({
  label,
  change,
  children,
}: SingleChangeCellProps) => {
  const { domainName, infoTooltip } = getChangeHeaderInfo(change)

  return (
    <Cell>
      <Flex flex="1 0" width="100%" justifyContent="space-between">
        <HStack align="center" space="s-4">
          {infoTooltip}
          <Text>{label || domainName}</Text>
        </HStack>
        {children}
      </Flex>
    </Cell>
  )
}

type MultipleChangesCellWrapperProps = {
  change: PayrollTimelineChangeInterface
  isExpandedByDefault?: boolean
  children: React.ReactNode
}
export const MultipleChangesCellWrapper = ({
  change,
  isExpandedByDefault = true,
  children,
}: MultipleChangesCellWrapperProps) => {
  const [expanded, setExpanded] = useState(isExpandedByDefault)

  const { domainName, infoTooltip } = getChangeHeaderInfo(change)

  return (
    <ExpandableCell expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <ExpandableCell.Title>
        <HStack align="center" space="s-4">
          {infoTooltip}
          {domainName}
        </HStack>
      </ExpandableCell.Title>
      <ExpandableCell.Note>{children}</ExpandableCell.Note>
    </ExpandableCell>
  )
}

type Props = {
  data: {
    change: PayrollTimelineChangeInterface
    fieldsChanges: ParsedDomainFieldChanges[]
    renderAll?: (change: PayrollTimelineChangeInterface) => React.ReactNode
  }
  isExpandedByDefault?: boolean
}
export const DomainChangesWidget = ({ data, isExpandedByDefault = true }: Props) => {
  const { change, fieldsChanges, renderAll } = data

  const hasIssues = isPlainObject(change.issues) && !isEmpty(change.issues)
  const issuesLevelColor =
    change.issues_level.id === PAYROLL_ISSUES_LEVEL_CRITICAL
      ? Token.color.danger
      : change.issues_level.id === PAYROLL_ISSUES_LEVEL_WARNING
      ? Token.color.warning
      : undefined

  if (!fieldsChanges.length) {
    return null
  }
  const customRenderedData = renderAll?.(change)

  if (!hasIssues && customRenderedData !== undefined) {
    return <>{customRenderedData}</>
  }
  if (!hasIssues && fieldsChanges.length === 1) {
    const { from, to, changeType, label } = fieldsChanges[0]

    return (
      <SingleChangeCellWrapper label={label} change={change}>
        <ValuesDiff from={from} to={to} type={changeType} />
      </SingleChangeCellWrapper>
    )
  }
  return (
    <MultipleChangesCellWrapper change={change} isExpandedByDefault={isExpandedByDefault}>
      {fieldsChanges.map(parsedField => {
        const { from, to, label, changeType } = parsedField
        return (
          <Details key={label}>
            <Details.Title>{label}</Details.Title>
            <Details.Content>
              <ValuesDiff from={from} to={to} type={changeType} />
            </Details.Content>
          </Details>
        )
      })}
      {hasIssues && (
        <VStack>
          {Object.entries(change.issues).map(([field, message]) => (
            <Details key={field}>
              <Details.Title>
                <Text color={issuesLevelColor}>{formatSnakeCase(field)}</Text>
              </Details.Title>
              <Details.Content>
                <HStack space="s-4" align="center">
                  <Icon
                    color={issuesLevelColor}
                    name="ExclamationMarkOutline"
                    size={15}
                  />
                  <Text color={issuesLevelColor}>{message}</Text>
                </HStack>
              </Details.Content>
            </Details>
          ))}
        </VStack>
      )}
    </MultipleChangesCellWrapper>
  )
}
