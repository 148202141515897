import pick from 'lodash/pick'

import {
  PayGroupInterface,
  PayrunPreviewPayloadInterface,
} from '@src/interfaces/payrollV2'

const formatNonCompliantFields = (values: PayGroupInterface) => {
  return {
    ...(values.pay_date_day ? { pay_date_day: values.pay_date_day.id } : null),
    ...(values.pay_period_start_day
      ? { pay_period_start_day: values.pay_period_start_day.id }
      : null),
    // BE expects values in different format than what it actually returns
  } as unknown as Pick<PayGroupInterface, 'pay_date_day' | 'pay_period_start_day'>
}

export const formatValuesToSubmit = (values: PayGroupInterface) => {
  return {
    ...values,
    ...formatNonCompliantFields(values),
  }
}

const payrunScheduleFields = [
  'pay_date_day',
  'pay_frequency',
  'pay_date_schedule',
  'pay_period_start_day',
  'cut_off_date_trigger',
  'cut_off_date_trigger_number_of_days',
  'pay_date_lands_on_weekend',
]

const payrunDefaultFields = {
  number_of_schedules: 12,
  report_submission_date_trigger: { id: 'after_pay_cycle_starts' },
  report_submission_date_days_after_cycle_ends: 0,
}

export const formatValuesToGetPayrunSchedule = (values: PayGroupInterface) => {
  return {
    ...pick(values, payrunScheduleFields),
    ...formatNonCompliantFields(values),
    ...payrunDefaultFields,
  } as unknown as PayrunPreviewPayloadInterface
}
