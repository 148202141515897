import React, { useMemo } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { payGroupRequests } from '@src/api/payrollV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'

import { PayGroupEditConfirm } from './Confirm'
import { PayGroupEditDetails } from './Details'

const tabsOrder = [
  {
    title: 'Details',
    key: 'details',
    path: ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT,
    component: PayGroupEditDetails,
  },
  {
    title: 'Preview',
    key: 'preview',
    path: ROUTES.APPS.PAYROLL_V2.PAY_GROUP.CONFIRM,
    component: PayGroupEditConfirm,
  },
]

type TabParams = { id?: string }
const getTabs = (params: TabParams) => {
  return tabsOrder.map(tab => ({
    ...tab,
    url: pathToUrl(tab.path, params),
  }))
}

export const PayGroupEdit = () => {
  const params = useParams<TabParams>()
  const tabs = useMemo(() => getTabs(params), [params])

  return (
    <PageWrapper>
      <PageHeader
        title="New paygroup"
        backUrl={ROUTES.APPS.PAYROLL_V2.SETTINGS.PAY_GROUPS}
      />
      <PageBody>
        <TabBarNavigation tabs={tabs} mb="s-16" />
        <Switch>
          <Form api={payGroupRequests}>
            {tabs.map(tab => (
              <Route exact key={tab.key} path={tab.path}>
                <tab.component />
              </Route>
            ))}
          </Form>
        </Switch>
      </PageBody>
    </PageWrapper>
  )
}
